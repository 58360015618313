import { Box, Card } from "@mui/material";
import React from "react";

import "../styles/PostCards.scss";

const PostCards = () => {
  return (
    <Card className="postCards">
      <Box>User Accounts</Box>
    </Card>
  );
};

export default PostCards;
